import { Box, Button, Container, Text } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactElement, ReactNode, useContext } from "react";
import ErrorPage from "components/Shared/ErrorPage";
import { Module } from "__generated__/gql/graphql";
import { useCurrentBrandData } from "utils/currentUserHooks";
import ContactUsButton from "components/Shared/ContactUsButton";
import { RestrictedModulePathContext } from "utils/RestrictedModulePath";
import { BRAND_INDEX } from "utils/links";
import { BRAND_LAYOUT_HOMEPAGES } from "components/Layout/brandLayoutUtils";

export const LABELS_BY_MODULE: Record<Module, string> = {
  [Module.ANALYTICS]: "Analytics",
  [Module.EMAIL_CAMPAIGNS]: "Email Campaigns",
  [Module.PROMOTIONS]: "Promotions",
  [Module.PURCHASE_ORDERS]: "Purchase Orders",
  [Module.SALES_PLANNER]: "Sales Planner",
};

type Props = {
  children: ReactNode;
};

export default function BrandModuleAccessGate({ children }: Props) {
  const brand = useCurrentBrandData();
  const restrictedModule = useContext(RestrictedModulePathContext);

  if (!restrictedModule) return children as ReactElement;

  const restrictedModuleLabel = LABELS_BY_MODULE[restrictedModule];

  return (
    <ErrorPage heading="Sorry!" subheading="Module Unavailable" isWithNavBar>
      <Container maxW="45ch">
        <Text
          fontSize="xl"
          fontWeight={500}
          mt={10}
          sx={{ b: { fontWeight: 800 } }}
        >
          The <b>{restrictedModuleLabel}</b> module is not enabled for{" "}
          <b>{brand?.name}</b>.
        </Text>

        <Text my={6}>
          Want to learn more?
          <ContactUsButton />
        </Text>

        <Box mt={10}>
          <NextLink
            href={
              brand?.layout ? BRAND_LAYOUT_HOMEPAGES[brand.layout] : BRAND_INDEX
            }
            passHref
            legacyBehavior
          >
            <Button as="a" colorScheme="lightPinkButton" size="lg">
              Go Home
            </Button>
          </NextLink>
        </Box>
      </Container>
    </ErrorPage>
  );
}
