import { Box } from "@chakra-ui/react";
import { FragmentType, getFragmentData, gql } from "__generated__/gql";
import AddWbr from "components/Shared/AddWbr";

const fragment = gql(/* GraphQL */ `
  fragment RetailerNameWithSuffixFragment on Retailer {
    name
    uniqueSuffix
  }
`);

type Props = {
  retailer: FragmentType<typeof fragment> | null | undefined;
};

export default function NameWithSuffix({ retailer: retailerFragment }: Props) {
  const retailer = getFragmentData(fragment, retailerFragment);
  if (!retailer) return null;
  return (
    <>
      {retailer.name && <AddWbr parts={retailer.name.split(/(\/)/)} />}
      {retailer.uniqueSuffix && (
        <Box as="span" opacity={0.5}>
          {" "}
          ({retailer.uniqueSuffix})
        </Box>
      )}
    </>
  );
}
