import { useCallback, useEffect } from "react";
import { MutationTuple } from "@apollo/client";
import useIsMounted from "utils/useIsMounted";
import { LocalMutationTuple } from "utils/useLocalMutation";
import useLatestRef from "utils/useLatestRef";
import { asyncVoid } from "utils/asyncVoidHandler";
import ConfirmModal, {
  ConfirmButton,
  ConfirmButtonProps,
  ConfirmModalProps,
} from ".";

export type ConfirmModalMutationProps<
  TData = unknown,
  TVariables = unknown,
> = Omit<ConfirmModalProps, "confirmButton"> & {
  mutation: MutationTuple<TData, TVariables> | LocalMutationTuple;
  confirmButtonLabel?: React.ReactNode;
  confirmButtonProps?: ConfirmButtonProps;
};

export default function ConfirmModalMutation<TData, TVariables>({
  confirmButtonLabel = "Confirm",
  confirmButtonProps,
  mutation,
  ...rest
}: ConfirmModalMutationProps<TData, TVariables>) {
  const { isOpen, onClose } = rest;
  const isMounted = useIsMounted();

  const [performDelete, { loading, error }] = mutation;

  const latestMutationRef = useLatestRef(mutation);

  const handleConfirm = useCallback(() => {
    asyncVoid(
      performDelete().then(() => {
        if (isMounted.current) onClose();
      }),
      true,
    );
  }, [onClose, performDelete, isMounted]);

  useEffect(() => {
    latestMutationRef.current?.[1]?.reset();
  }, [latestMutationRef, isOpen]);

  return (
    <ConfirmModal
      error={error}
      confirmButton={
        <ConfirmButton
          colorScheme="red"
          {...confirmButtonProps}
          onClick={handleConfirm}
          isLoading={loading}
        >
          {confirmButtonLabel}
        </ConfirmButton>
      }
      {...rest}
    />
  );
}
