import { Icon } from "@chakra-ui/react";
import { MdRefresh } from "react-icons/md";
import TooltipIconButton, {
  TooltipIconButtonProps,
} from "components/Shared/TooltipIconButton";
import { OptionalKeys } from "utils/typescriptUtilis";

type Props = OptionalKeys<TooltipIconButtonProps, "label" | "icon">;

export default function RefreshButton(props: Props) {
  return (
    <TooltipIconButton
      icon={<Icon as={MdRefresh} />}
      label="Refresh"
      variant="ghost"
      color="gray.600"
      tooltipProps={{
        placement: "bottom",
        closeOnClick: true,
        closeOnPointerDown: true,
        closeOnEsc: true,
        shouldWrapChildren: true,
      }}
      minH="2.25em"
      {...props}
    />
  );
}
