import { ReactElement, ReactNode, useRef } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Collapse,
  AlertDialogProps,
  ButtonProps,
} from "@chakra-ui/react";

export type ConfirmButtonProps = ButtonProps;

export function ConfirmButton(props: ConfirmButtonProps) {
  return <Button size="sm" ml={3} {...props} />;
}

export type ConfirmModalProps = Pick<AlertDialogProps, "isOpen" | "onClose"> & {
  confirmButton: ReactElement;
  confirmText?: ReactNode;
  error?: Error | null;
  label: ReactNode;
};

export default function ConfirmModal({
  confirmButton,
  confirmText = "Are you sure you want to do this?",
  error,
  isOpen,
  label,
  onClose,
}: ConfirmModalProps) {
  const cancelRef = useRef<HTMLButtonElement>(null);
  return (
    <AlertDialog {...{ isOpen, onClose }} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            color="magenta.veryDark"
          >
            {label}
          </AlertDialogHeader>

          <AlertDialogBody>
            {confirmText}

            <Collapse in={!!error} unmountOnExit>
              <Alert status="error" variant="left-accent" my={6}>
                <AlertIcon />
                <AlertDescription>{error?.message}</AlertDescription>
              </Alert>
            </Collapse>
          </AlertDialogBody>

          <AlertDialogFooter gap={2}>
            <Button
              size="sm"
              ref={cancelRef}
              onClick={onClose}
              variant="ghost"
              colorScheme="gray"
            >
              Cancel
            </Button>
            {confirmButton}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
