import { Box, Flex, FlexProps, Image, Link } from "@chakra-ui/react";
import { FragmentType, getFragmentData, gql } from "__generated__/gql";

const fragment = gql(/* GraphQL */ `
  fragment UserChipComponent on User {
    id
    fullName
    email
    image
  }
`);

type UserChipProps = FlexProps & {
  isEmailLinked?: boolean;
  user: FragmentType<typeof fragment> | null | undefined;
};

export default function UserChip({
  isEmailLinked,
  user: userFragment,
  ...rest
}: UserChipProps) {
  const user = getFragmentData(fragment, userFragment);
  if (user === null) return null;

  return (
    <Flex alignItems="center" {...rest}>
      <Image
        src={user?.image || undefined}
        boxSize="1.75em"
        borderRadius="full"
        fallback={
          <Box
            width="1.75em"
            height="1.75em"
            background="magenta.veryDark"
            borderRadius="full"
          />
        }
        alt="User profile picture"
      />
      <Box ml=".5em" minW={0} lineHeight="normal">
        <Box isTruncated>{user?.fullName}</Box>
        <Box fontSize=".85em" opacity={0.7} isTruncated>
          {isEmailLinked && user ? (
            <Link href={`mailto:${user.email}`}>{user.email}</Link>
          ) : (
            user?.email
          )}
        </Box>
      </Box>
    </Flex>
  );
}
