import {
  DefaultContext,
  DocumentNode,
  LazyQueryHookOptions,
  MutationHookOptions,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useLazyQuery,
  useMutation,
} from "@apollo/client";
import { useContext, useMemo } from "react";
import {
  useCurrentBrandId,
  useIsInvalidSubscription,
} from "utils/currentUserHooks";
import { RestrictedModulePathContext } from "utils/RestrictedModulePath";
import {
  LoadMoreQueryHookOptions,
  LoadMoreQueryResult,
  QueryResult,
  useLoadMoreQuery,
  useQuery,
} from ".";

export function getActiveBrandIdContext(activeBrandId: UUID) {
  return { activeBrandId };
}

export function addActiveBrandIdToContext(
  activeBrandId: UUID | null | undefined,
  context?: DefaultContext,
) {
  if (!activeBrandId) return context;
  return { ...context, ...getActiveBrandIdContext(activeBrandId) };
}

export function useActiveBrandIdContext(context?: DefaultContext) {
  const brandId = useCurrentBrandId();
  return useMemo(
    () => addActiveBrandIdToContext(brandId, context),
    [brandId, context],
  );
}

type UseBrandQueryOptions = { context?: DefaultContext; skip?: boolean };

export function useBrandQueryOptions(
  options?: UseBrandQueryOptions,
): [options: UseBrandQueryOptions, extra: { isAwaitingBrand: boolean }] {
  const brandId = useCurrentBrandId();
  const isInvalidSubscription = useIsInvalidSubscription();
  const restrictedModule = useContext(RestrictedModulePathContext);

  const isAwaitingBrand =
    !brandId || isInvalidSubscription !== false || restrictedModule !== false;

  return [
    {
      skip: isAwaitingBrand || options?.skip,
      context: addActiveBrandIdToContext(brandId, options?.context),
    },
    { isAwaitingBrand },
  ];
}

/**
 * A version of `useQuery` that restricts results to the current brand.
 */
export function useBrandQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>,
): QueryResult<TData, TVariables> {
  const [brandQueryOptions, { isAwaitingBrand }] =
    useBrandQueryOptions(options);
  const { loading, ...result } = useQuery<TData, TVariables>(query, {
    ...options,
    ...brandQueryOptions,
  });

  return {
    ...result,
    loading: loading || (typeof window !== "undefined" && isAwaitingBrand),
  };
}

export function useBrandLazyQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables>,
) {
  const context = useActiveBrandIdContext(options?.context);
  return useLazyQuery<TData, TVariables>(query, {
    ...options,
    context,
  });
}

export function useBrandMutation<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables>,
) {
  const context = useActiveBrandIdContext(options?.context);
  return useMutation<TData, TVariables>(mutation, {
    ...options,
    context,
  });
}

export function useBrandLoadMoreQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: LoadMoreQueryHookOptions<TData, TVariables>,
): LoadMoreQueryResult<TData, TVariables> {
  const [brandQueryOptions, { isAwaitingBrand }] =
    useBrandQueryOptions(options);
  const { loading, ...result } = useLoadMoreQuery(query, {
    ...options,
    ...brandQueryOptions,
  });
  return {
    ...result,
    loading: loading || (typeof window !== "undefined" && isAwaitingBrand),
  };
}
