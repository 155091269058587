import { createContext, ReactNode } from "react";
import { useRouter } from "next/router";
import { invert } from "lodash";
import { useBrandModules } from "utils/currentUserHooks";
import {
  ANALYTICS_INDEX,
  BRAND_PROMOTIONS_INDEX,
  EMAIL_CAMPAIGNS_INDEX,
  PURCHASE_ORDERS_INDEX,
  SALES_PLAN_INDEX,
} from "utils/links";
import { Module } from "__generated__/gql/graphql";

const RESTRICTED_PATHS_BY_MODULE: Record<Module, string> = {
  [Module.ANALYTICS]: ANALYTICS_INDEX,
  [Module.EMAIL_CAMPAIGNS]: EMAIL_CAMPAIGNS_INDEX,
  [Module.PROMOTIONS]: BRAND_PROMOTIONS_INDEX,
  [Module.PURCHASE_ORDERS]: PURCHASE_ORDERS_INDEX,
  [Module.SALES_PLANNER]: SALES_PLAN_INDEX,
};

const MODULES_BY_RESTRICTED_PATHS = invert(
  RESTRICTED_PATHS_BY_MODULE,
) as Record<string, Module>;

const RESTRICTED_PATHS = Object.keys(MODULES_BY_RESTRICTED_PATHS);

export function useRestrictedModulePath() {
  const { pathname } = useRouter();
  const modules = useBrandModules();

  const restrictedPath = RESTRICTED_PATHS.find((p) => pathname.startsWith(p));

  if (!restrictedPath) return false;

  if (!modules) return;

  const restrictedModule = MODULES_BY_RESTRICTED_PATHS[restrictedPath];

  if (modules.includes(restrictedModule)) return false;

  return restrictedModule;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const RestrictedModulePathContext =
  createContext<ReturnType<typeof useRestrictedModulePath>>(false);

type RestrictedModulePathProviderProps = {
  children: ReactNode;
};

export function RestrictedModulePathProvider({
  children,
}: RestrictedModulePathProviderProps) {
  const restrictedModule = useRestrictedModulePath();
  return (
    <RestrictedModulePathContext.Provider value={restrictedModule}>
      {children}
    </RestrictedModulePathContext.Provider>
  );
}
