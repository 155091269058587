import { useEffect, useRef, useState } from "react";

export default function useTimeInterval(interval: number | null) {
  const [time, setTime] = useState(new Date());

  const intervalIdRef = useRef<NodeJS.Timer>();

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }

    if (interval) {
      intervalIdRef.current = setInterval(() => {
        setTime(new Date());
      }, interval);
    }

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [interval]);

  return time;
}
