import { MenuItem, MenuItemProps, useDisclosure } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { OptionalKeys } from "utils/typescriptUtilis";
import ConfirmModalMutation, {
  ConfirmModalMutationProps,
} from "./ConfirmModalMutation";

type Props<TData = unknown, TVariables = unknown> = Omit<
  OptionalKeys<ConfirmModalMutationProps<TData, TVariables>, "label">,
  "isOpen" | "onClose"
> & {
  menuItemProps?: Partial<Omit<MenuItemProps, "onClick">>;
};

export default function DeleteConfirmMenuItem<TData, TVariables>({
  menuItemProps,
  label = "Delete",
  ...rest
}: Props<TData, TVariables>) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <MenuItem onClick={onOpen} icon={<DeleteIcon />} {...menuItemProps}>
        {label}
      </MenuItem>

      <ConfirmModalMutation
        {...{ isOpen, label, onClose }}
        confirmButtonLabel="Delete"
        confirmText="Are you sure? You won’t be able to undo this action afterwards."
        {...rest}
      />
    </>
  );
}
