import { Center, List, ListProps, Spinner } from "@chakra-ui/react";
import { createContext, ReactNode, useMemo } from "react";
import { useCurrentUserId } from "utils/currentUserHooks";

type CommentContext = { userId: UUID | undefined };

export const commentContext = createContext<CommentContext>({
  userId: undefined,
});

type Props = ListProps & {
  isEmpty?: boolean;
  isLoading?: boolean;
  isReverse?: boolean;
  noCommentsText?: ReactNode;
};

export default function CommentListContainer({
  children,
  isEmpty,
  isLoading,
  isReverse,
  noCommentsText = "No comments added yet.",
  ...rest
}: Props) {
  const userId = useCurrentUserId();
  const contextValue = useMemo<CommentContext>(() => ({ userId }), [userId]);
  return (
    <commentContext.Provider value={contextValue}>
      <List
        display="flex"
        flexDirection="column"
        overflowY="auto"
        px={2}
        position="relative"
        data-is-reverse={isReverse || undefined}
        className="comment-list-container"
        sx={{ "&[data-is-reverse=true]": { flexDirection: "column-reverse" } }}
        {...rest}
      >
        {isEmpty && (
          <Center
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            width="100%"
            height="100%"
            color="gray.400"
            fontSize="sm"
            backgroundColor="rgba(255, 255, 255, .5)"
          >
            {isLoading ? <Spinner size="xl" /> : noCommentsText}
          </Center>
        )}

        {children}
      </List>
    </commentContext.Provider>
  );
}
