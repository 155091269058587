import dynamic from "next/dynamic";
import {
  BoxProps,
  SkeletonText as BaseSkeletonText,
  SkeletonTextProps,
  Stack,
} from "@chakra-ui/react";

function SkeletonText(props: SkeletonTextProps) {
  return <BaseSkeletonText skeletonHeight="1.25em" spacing=".5em" {...props} />;
}

type Props = Pick<SkeletonTextProps, "isLoaded"> &
  BoxProps & {
    skeletonProps?: Omit<SkeletonTextProps, "isLoaded">;
    markdown?: string | null;
  };

// eslint-disable-next-line @typescript-eslint/naming-convention
const DynamicRendered = dynamic(() => import("./Renderer"), {
  loading: () => <SkeletonText noOfLines={4} />,
});

export default function MarkdownText({
  isLoaded = true,
  markdown,
  skeletonProps = { noOfLines: 4 },
  ...rest
}: Props) {
  return (
    <SkeletonText {...{ isLoaded, ...skeletonProps }}>
      <Stack {...rest}>
        <DynamicRendered markdown={markdown} />
      </Stack>
    </SkeletonText>
  );
}
