import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "@chakra-ui/react";

export default function MarkdownHelpText() {
  return (
    <>
      You can use{" "}
      <Link
        href="https://www.markdownguide.org/cheat-sheet/#basic-syntax"
        isExternal
        color="magenta.dark"
        _hover={{ color: "magenta.veryDark" }}
      >
        Markdown
        <ExternalLinkIcon boxSize=".75em" ml=".1em" mr=".1em" mt="-.15em" />
      </Link>{" "}
      here to format your text.
    </>
  );
}
