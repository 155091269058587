import ActiveLink, { ActiveLinkProps } from "components/Shared/ActiveLink";
import Link, { LinkProps } from "components/Shared/Link";
import TooltipIconButton, {
  TooltipIconButtonProps,
} from "components/Shared/TooltipIconButton";

export type TooltipIconLinkButtonProps = TooltipIconButtonProps & LinkProps;

export default function TooltipIconLinkButton({
  ...rest
}: TooltipIconLinkButtonProps) {
  return <TooltipIconButton as={Link} {...rest} />;
}

export type TooltipIconActiveLinkButtonProps = TooltipIconButtonProps &
  ActiveLinkProps;

export function TooltipIconActiveLinkButton({
  ...rest
}: TooltipIconActiveLinkButtonProps) {
  return <TooltipIconButton as={ActiveLink} {...rest} />;
}
