import { Container, Heading, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import CactusBackground from "components/Shared/CactusBackground";
import BrandName from "components/Shared/BrandName";
import LinkButton from "components/Shared/LinkButton";

export default function InvalidSubscription() {
  return (
    <CactusBackground
      isWithNavBar
      graphicsProps={{
        sx: {
          "@media (max-height: 700px), (max-width: 1000px)": {
            opacity: "0.4",
          },
        },
      }}
    >
      <Container
        textAlign="center"
        color="magenta.veryDark"
        p="20vh 1rem 1rem"
        pos="relative"
      >
        <Heading>Subscribe for Access</Heading>

        <Text fontSize="1.25em" my={8}>
          The Pitchable subscription for <BrandName /> has ended. To continue
          using Pitchable, please purchase a new subscription, which is
          available for purchase with or without the purchase of other RodeoCPG
          services.
        </Text>

        <LinkButton
          isExternal
          href="https://www.rodeocpg.com/contact-us"
          colorScheme="pinkButton"
          whiteSpace="normal"
          rightIcon={<ExternalLinkIcon />}
        >
          Contact Us to Learn More
        </LinkButton>
      </Container>
    </CactusBackground>
  );
}
