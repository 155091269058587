import { ReactNode } from "react";
import { Box, Button, Center, Flex, Spinner } from "@chakra-ui/react";
import Comment from "components/Shared/Comments/Comment";
import CommentListContainer from "components/Shared/Comments/ListContainer";
import CommentRelatedItem from "components/Shared/Comments/RelatedItem";
import RefreshButton from "components/Shared/RefreshButton";
import { useBrandLoadMoreQuery } from "utils/apollo/hooks/brand";
import { asyncVoid } from "utils/asyncVoidHandler";
import { useCurrentBrandId } from "utils/currentUserHooks";
import { formatInteger, titleCase } from "utils/formatting";
import { gql } from "__generated__/gql";
import { NAVBAR_HEIGHT } from "components/Layout/constants";

export const COMMENT_LABEL = "status update";
export const COMMENTS_TITLE = `${titleCase(COMMENT_LABEL)}s`;

const query = gql(/* GraphQL */ `
  query CommentsIndex(
    $brandId: UUID!
    $filter: CommentFilter
    $orderBy: [CommentsOrderBy!] = [CREATED_AT_DESC]
    $limit: Int = 10
    $offset: Int = 0
  ) {
    commentsForBrand(
      brandId: $brandId
      filter: $filter
      orderBy: $orderBy
      first: $limit
      offset: $offset
    ) {
      totalCount
      pageInfo {
        hasNextPage
      }
      nodes {
        id
        ...CommentDisplay
        ...CommentRelatedItem
      }
    }
  }
`);

type Props = {
  headerLeft?: ReactNode;
  headerRight?: ReactNode;
};

export default function Comments({ headerLeft, headerRight }: Props) {
  const brandId = useCurrentBrandId();

  const { data, fetchNextPage, refetch, loading } = useBrandLoadMoreQuery(
    query,
    {
      skip: !brandId,
      variables: brandId ? { brandId } : undefined,
      pageSize: 10,
      getListData: (d) => d.commentsForBrand?.nodes,
      fetchPolicy: "cache-and-network",
      notifyOnNetworkStatusChange: true,
    },
  );

  const isLoading = loading || typeof window === "undefined";

  const numMore =
    data?.commentsForBrand &&
    Math.max(
      data.commentsForBrand.totalCount - data.commentsForBrand.nodes.length,
      0,
    );

  return (
    <>
      <Flex
        alignItems="center"
        mt={-3}
        mb={8}
        py={3}
        position="sticky"
        top={NAVBAR_HEIGHT}
        backgroundColor="rgba(255, 255, 255, 0.95)"
        borderBottomWidth={2}
        zIndex={10}
      >
        {headerLeft}
        <Flex alignItems="center" ml="auto">
          <RefreshButton
            onClick={() => asyncVoid(refetch())}
            isLoading={isLoading}
          />
          {headerRight}
        </Flex>
      </Flex>

      <CommentListContainer overflowY="visible" px={0}>
        {data?.commentsForBrand?.nodes?.map((comment) => (
          <Comment
            key={comment.id}
            data={comment}
            headerRight={<CommentRelatedItem data={comment} />}
          />
        ))}
      </CommentListContainer>

      {!data?.commentsForBrand?.totalCount && (
        <Center color="gray.500" p={8}>
          {isLoading ? (
            <Spinner size="xl" color="gray.600" />
          ) : (
            <Box width="100%" py={2} textAlign="center">
              No {COMMENT_LABEL}s have been added yet.
            </Box>
          )}
        </Center>
      )}

      {!!data?.commentsForBrand?.nodes?.length && (
        <Box
          textAlign="center"
          color="gray.600"
          fontSize="sm"
          fontWeight={300}
          p={4}
          mt={4}
          mb={10}
        >
          {numMore === 0 ? "No" : formatInteger(numMore)} more {COMMENT_LABEL}
          {numMore === 1 ? "" : "s"}…{" "}
          <Button
            onClick={() => asyncVoid(fetchNextPage())}
            isDisabled={!data?.commentsForBrand?.pageInfo.hasNextPage}
            isLoading={loading}
            variant="outline"
            colorScheme="lightPinkButton"
            size="xs"
            fontSize=".8em"
            height="auto"
            py=".25em"
          >
            Load More
          </Button>
        </Box>
      )}
    </>
  );
}
