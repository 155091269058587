import { Box, BoxProps } from "@chakra-ui/react";
import { useCurrentBrandData } from "utils/currentUserHooks";

type Props = Omit<BoxProps, "children">;

export default function BrandName(props: Props) {
  const brand = useCurrentBrandData();
  return (
    <Box as="span" {...props}>
      {brand?.name || "current brand"}
    </Box>
  );
}
