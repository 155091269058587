import { useMemo } from "react";
import CommentForm, { CommentData } from "components/Shared/Comments/Form";
import { FragmentType, getFragmentData, gql } from "__generated__/gql";
import { useBrandMutation } from "utils/apollo/hooks/brand";

const fragment = gql(/* GraphQL */ `
  fragment CommentEdit on Comment {
    id
    body
  }
`);

const mutation = gql(/* GraphQL */ `
  mutation UpdateCommentBody($commentId: UUID!, $body: String!) {
    updateComment(input: { id: $commentId, patch: { body: $body } }) {
      comment {
        id
        body
        updatedAt
      }
    }
  }
`);

type Props = {
  data: FragmentType<typeof fragment>;
  onCloseEditing: () => void;
};

export default function EditComment({ data, onCloseEditing }: Props) {
  const comment = getFragmentData(fragment, data);

  const [performUpdate, { loading }] = useBrandMutation(mutation);

  const defaultValues = useMemo(() => {
    if (!comment.body) return;
    return { body: comment.body };
  }, [comment.body]);

  const handleValidSubmit = async ({ body }: CommentData) => {
    const result = await performUpdate({
      variables: { commentId: comment.id, body },
    });
    onCloseEditing();
    return result;
  };

  return (
    <CommentForm
      defaultValues={defaultValues}
      onEscape={() => onCloseEditing()}
      onValidSubmit={handleValidSubmit}
      isAutoFocused
      isSubmitting={loading}
      submitButtonLabel="Update"
      successMessage="Comment updated successfully"
    />
  );
}
