import { Menu, MenuItem, MenuList } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import type { ApolloCache, MutationUpdaterFunction } from "@apollo/client";
import { DocumentType, gql } from "__generated__/gql";
import { useBrandMutation } from "utils/apollo/hooks/brand";
import DeleteConfirmMenuItem from "components/Shared/ConfirmModal/DeleteConfirmMenuItem";
import MoreMenuButton from "components/Shared/MoreMenuButton";
import { removeDeletedRecords } from "utils/apollo/cacheHelpers";
import { assertPresence } from "utils/assertions";
import type { DocumentVariablesType } from "utils/apollo/hooks";

const deleteMutation = gql(/* GraphQL */ `
  mutation DeleteComment($commentId: UUID!) {
    deleteComment(input: { id: $commentId }) {
      comment {
        id
      }
    }
  }
`);

export type DeleteUpdateFunction = MutationUpdaterFunction<
  DocumentType<typeof deleteMutation>,
  DocumentVariablesType<typeof deleteMutation>,
  unknown,
  ApolloCache<unknown>
>;

type Props = {
  commentId: UUID;
  deleteUpdate?: DeleteUpdateFunction;
  onEdit: () => void;
};

export default function CommentMenu({
  commentId,
  deleteUpdate,
  onEdit,
}: Props) {
  const deleteMutationTuple = useBrandMutation(deleteMutation, {
    variables: { commentId },
    update: (cache, result, options) => {
      deleteUpdate?.(cache, result, options);
      const deletedComment = result.data?.deleteComment?.comment;
      assertPresence(deletedComment);
      removeDeletedRecords({
        cache,
        baseFieldName: "comments",
        deletedRecords: [deletedComment],
      });
      removeDeletedRecords({
        cache,
        baseFieldName: "commentsForBrand",
        deletedRecords: [deletedComment],
      });
    },
  });
  return (
    <Menu>
      <MoreMenuButton
        aria-label="Comment Actions"
        size="xs"
        ml={1}
        mr={-2}
        my={-2}
        color="gray.600"
        display="none"
        sx={{ "li:hover &": { display: "inline-block" } }}
      />
      <MenuList>
        <MenuItem icon={<EditIcon />} onClick={onEdit}>
          Edit Comment
        </MenuItem>
        <DeleteConfirmMenuItem
          mutation={deleteMutationTuple}
          label="Delete Comment"
        />
      </MenuList>
    </Menu>
  );
}
