import { ForwardedRef, forwardRef } from "react";
import { Button, ButtonProps } from "@chakra-ui/react";
import Link, { LinkProps } from "components/Shared/Link";

export type LinkButtonProps = ButtonProps & LinkProps;

function LinkButton(
  props: LinkButtonProps,
  ref: ForwardedRef<HTMLAnchorElement>,
) {
  return (
    <Button as={Link} ref={ref as ForwardedRef<HTMLButtonElement>} {...props} />
  );
}

export default forwardRef(LinkButton);
