import {
  MenuButton,
  IconButton,
  MenuButtonProps,
  IconButtonProps,
  Icon,
} from "@chakra-ui/react";
import { MdMoreVert } from "react-icons/md";
import { useGetTransparency } from "utils/colorHooks";

type Props = MenuButtonProps & IconButtonProps;

export default function MoreMenuButton(props: Props) {
  const getTransparency = useGetTransparency();
  return (
    <MenuButton
      as={IconButton}
      icon={<Icon as={MdMoreVert} boxSize="1.25em" />}
      height="auto"
      px=".5em"
      py=".5em"
      borderRadius="lg"
      variant="ghost"
      size="sm"
      colorScheme="gray"
      color="gray.500"
      lineHeight={1}
      _hover={{ backgroundColor: getTransparency("gray.300", 0.4) }}
      {...props}
    />
  );
}
