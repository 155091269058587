import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";

export default function ContactUsButton() {
  return (
    <Button
      as="a"
      variant="outline"
      colorScheme="lightPink"
      size="sm"
      mx={2}
      my={2}
      href="https://www.rodeocpg.com/contact-us"
      target="_blank"
      rel="noopener noreferrer"
      rightIcon={<ExternalLinkIcon boxSize=".8em" />}
    >
      Contact Us
    </Button>
  );
}
