import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { BrandLayout, Module } from "__generated__/gql/graphql";
import { useCurrentBrandData } from "utils/currentUserHooks";
import {
  PRODUCTS_INDEX,
  ANALYTICS_INDEX,
  SETTINGS_BASE,
  BRAND_INDEX,
} from "utils/links";
import { asyncVoid } from "utils/asyncVoidHandler";

export const ANALYTICS_LAYOUT_PATH_PREFIXES = [
  PRODUCTS_INDEX,
  ANALYTICS_INDEX,
  SETTINGS_BASE,
] as const;

export const BRAND_LAYOUT_HOMEPAGES: Record<BrandLayout, string> = {
  [BrandLayout.DEFAULT]: BRAND_INDEX,
  [BrandLayout.ANALYTICS]: ANALYTICS_INDEX,
};

/**
 * Determines if the analytics layout is disabled based on the provided modules.
 * @param modules - An array of modules.
 * @returns A boolean value indicating whether the analytics layout is disabled.
 */
export function getIsAnalyticsLayoutDisabled(modules: Module[] | undefined) {
  return !(modules?.length === 1 && modules?.[0] === Module.ANALYTICS);
}

export function useIsAnalyticsLayoutPath() {
  const router = useRouter();
  const brand = useCurrentBrandData();

  const isAnalyticsLayout =
    brand?.layout && brand.layout === BrandLayout.ANALYTICS;

  if (typeof isAnalyticsLayout === "boolean") return isAnalyticsLayout;

  if (
    router.pathname === "/" ||
    ANALYTICS_LAYOUT_PATH_PREFIXES.some((p) => router.pathname.startsWith(p))
  ) {
    return;
  }

  return false;
}

export function useBrandLayoutHomeRedirect() {
  const [isRedirecting, setIsRedirecting] = useState<boolean>();
  const router = useRouter();
  const brand = useCurrentBrandData();

  useEffect(() => {
    if (
      brand?.layout &&
      brand.layout !== BrandLayout.DEFAULT &&
      router.pathname === "/"
    ) {
      setIsRedirecting(true);
      const layoutHome = BRAND_LAYOUT_HOMEPAGES[brand.layout];
      asyncVoid(router.replace(layoutHome));
    } else if (brand?.layout) {
      setIsRedirecting(false);
    }
  }, [router, brand?.layout]);

  return isRedirecting;
}

export function usePrefetchBrandLayoutHome() {
  const router = useRouter();
  useEffect(() => {
    Object.values(BRAND_LAYOUT_HOMEPAGES).forEach((p) => {
      if (p !== "/") {
        asyncVoid(router.prefetch(p));
      }
    });
  }, [router]);
}
