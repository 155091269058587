import { ForwardedRef, forwardRef } from "react";
import ActiveLink, { ActiveLinkProps } from "components/Shared/ActiveLink";
import NavBarButton from "components/Layout/NavBar/NavBarButton";

type MenuItemLinkProps = Omit<ActiveLinkProps, "children"> & {
  label: string;
};

function NavBarLink(
  { ...rest }: MenuItemLinkProps,
  ref: ForwardedRef<HTMLAnchorElement & HTMLButtonElement>,
) {
  return (
    <NavBarButton
      as={ActiveLink}
      ref={ref}
      color="gray.200"
      _activeLink={{ fontWeight: 800, color: "white" }}
      activePlaceholderProps={{ fontWeight: 800 }}
      {...rest}
    />
  );
}

export default forwardRef(NavBarLink);
