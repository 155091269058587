import { ReactNode } from "react";
import { Box, Tooltip } from "@chakra-ui/react";
import {
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  intlFormatDistance,
  parseISO,
} from "date-fns";
import { makeFormatDate } from "utils/formatting";
import useTimeInterval from "utils/useTimeInterval";

type FormatOptions = Partial<Parameters<typeof intlFormatDistance>[2]> & {
  isAgoSuffixRemoved?: boolean;
};

export function format(
  value: ISODateTime,
  { isAgoSuffixRemoved, ...rest }: FormatOptions = {},
) {
  const now = new Date();
  const dateValue = parseISO(value);

  const months = differenceInMonths(now, dateValue);
  const secs = differenceInSeconds(now, dateValue);

  const formatted = intlFormatDistance(dateValue, now, {
    numeric: secs < 60 ? "auto" : "always",
    unit: months > 1 && months < 12 ? "month" : undefined,
    ...rest,
  });

  if (isAgoSuffixRemoved) return formatted.replace(" ago", "");

  return formatted;
}

function getInterval(value: ISODateTime) {
  const minutes = differenceInMinutes(new Date(), parseISO(value));

  if (minutes < 1) return 1000;

  if (minutes < 60) return 60 * 1000;

  if (minutes < 60 * 24) return 60 * 60 * 1000;

  return null;
}

type Props = {
  datetime: ISODateTime;
  formatOptions?: FormatOptions;
  leftIcon?: ReactNode;
};

export default function RelativeTime({
  datetime,
  formatOptions,
  leftIcon,
}: Props) {
  const interval = getInterval(datetime);
  const now = useTimeInterval(interval);
  return (
    <Tooltip
      label={makeFormatDate("PPPPpp")(datetime)}
      hasArrow
      placement="top-end"
      whiteSpace="nowrap"
      maxW="100%"
    >
      <Box
        key={now.toISOString()}
        as="time"
        dateTime={datetime}
        whiteSpace="nowrap"
        className={
          interval && interval <= 1000 ? "use-tabular-nums" : undefined
        }
        sx={{
          "&.use-tabular-nums": {
            fontVariantNumeric: "tabular-nums",
          },
        }}
      >
        {leftIcon}
        {format(datetime, formatOptions)}
      </Box>
    </Tooltip>
  );
}
