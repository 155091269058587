import { KeyboardEventHandler, ReactNode, useEffect, useRef } from "react";
import { Box, Button, Flex, Textarea } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Form from "components/Shared/Forms/Form";
import createFormFieldComponents from "components/Shared/Forms/FormField";
import {
  getPostgraphileErrorMatchersForFields,
  makePostgraphileErrorProcessor,
} from "utils/PostgraphileErrorExtensions";
import useCreateValidSubmitHandler from "components/Shared/Forms/useCreateValidSubmitHandler";
import { useCurrentUserId } from "utils/currentUserHooks";
import { assertPresence } from "utils/assertions";
import MarkdownHelpText from "components/Shared/MarkdownHelpText";

const FIELDS = ["body"] as const;

type Inputs = { body: string };

const DEFAULT_VALUES: Inputs = { body: "" };

const schema: yup.ObjectSchema<Inputs> = yup.object().shape({
  body: yup
    .string()
    .trim()
    .ensure()
    // eslint-disable-next-line no-template-curly-in-string
    .required("${label} is required to submit")
    .min(2)
    .label("Comment text"),
});

const { FormField } = createFormFieldComponents<Inputs>({
  schema,
});

const processApolloError = makePostgraphileErrorProcessor<Inputs>(
  getPostgraphileErrorMatchersForFields(FIELDS, { type: "Comment" }),
);

export type CommentData = Inputs & { userId: UUID };

type Props = {
  defaultValues?: Inputs;
  isAutoFocused?: boolean;
  isDisabled?: boolean;
  isResetOnComplete?: boolean;
  isSubmitting?: boolean;
  onEscape?: KeyboardEventHandler<HTMLTextAreaElement>;
  onValidSubmit: (formData: CommentData) => unknown;
  placeholder?: string;
  submitButtonLabel?: ReactNode;
  successMessage?: ReactNode;
};

export default function CommentForm({
  defaultValues = DEFAULT_VALUES,
  isAutoFocused,
  isDisabled: isDisabledProp,
  isResetOnComplete,
  isSubmitting,
  onEscape,
  onValidSubmit,
  placeholder = "Add a comment…",
  submitButtonLabel = "Submit",
  successMessage,
}: Props) {
  const formContext = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    formContext.reset(defaultValues);
  }, [formContext, defaultValues]);

  const formRef = useRef<HTMLFormElement>(null);

  const userId = useCurrentUserId();

  const isDisabled = isDisabledProp || !userId;

  const [handleValidSubmit] = useCreateValidSubmitHandler(
    async (formData: Inputs) => {
      assertPresence(userId, "Could not determine current user ID!");
      const result = await onValidSubmit({ ...formData, userId });
      if (isResetOnComplete) formContext.reset(DEFAULT_VALUES);
      return result;
    },
    {
      processApolloError,
      successMessage,
    },
  );

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (evt) => {
    if (
      evt.key === "Enter" &&
      (evt.shiftKey || evt.ctrlKey || evt.altKey || evt.metaKey) &&
      formRef.current
    ) {
      evt.preventDefault();
      formRef.current.requestSubmit();
    } else if (evt.key === "Escape" && onEscape) {
      evt.preventDefault();
      onEscape(evt);
    }
  };

  return (
    <Form
      {...{ formContext, isDisabled }}
      formRef={formRef}
      onValidSubmit={handleValidSubmit}
    >
      <FormField
        name="body"
        renderInput={(props) => (
          <Textarea {...props} onKeyDown={handleKeyDown} />
        )}
        label={null}
        placeholder={placeholder}
        my={1}
        autoFocus={isAutoFocused}
      />

      <Flex gap={2}>
        <Box
          fontSize=".7em"
          opacity={0.5}
          alignSelf="flex-start"
          lineHeight="normal"
        >
          <MarkdownHelpText /> Press <code>Shift</code> + <code>Enter</code> to
          submit.
        </Box>
        <Button
          type="submit"
          colorScheme="pinkButton"
          size="xs"
          isDisabled={isDisabled || !formContext.formState.isDirty}
          isLoading={isSubmitting}
          ml="auto"
          mt={1}
          alignSelf="flex-end"
        >
          {submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}
