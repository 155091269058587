import {
  Flex,
  Icon,
  IconButton,
  MenuDivider,
  MenuItem,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import {
  MdAdminPanelSettings,
  MdComment,
  MdFolder,
  MdSettings,
} from "react-icons/md";
import { useApolloClient } from "@apollo/client";
import {
  useCurrentBrandData,
  useCurrentUserData,
} from "utils/currentUserHooks";
import SwitchAccountMenuItem from "components/Core/Auth/SwitchAccount/MenuItem";
import {
  ADMIN_INDEX,
  ANALYTICS_DATA_SOURCES,
  ANALYTICS_INDEX,
  BRAND_SETTINGS,
  COMMENTS_INDEX,
  DOCUMENTS_INDEX,
  PRODUCTS_INDEX,
} from "utils/links";
import { asyncVoid } from "utils/asyncVoidHandler";
import {
  BRAND_LAYOUT_HOMEPAGES,
  useIsAnalyticsLayoutPath,
} from "components/Layout/brandLayoutUtils";
import TooltipIconLinkButton from "components/Layout/NavBar/TooltipIconLinkButton";
import { COMMENTS_TITLE } from "components/Comments";
import NavBarContainer from "./Container";
import MenuItemLink from "./MenuItemLink";
import NavBarLogo from "./Logo";
import NavBarAccountMenu from "./AccountMenu";
import NavBarLink from "./NavBarLink";

type NavBarProps = {
  handleShowOrHideSidebar: () => void;
  isMobileSideBarHidden?: boolean;
  isMobileSideBarOpen: boolean;
};

export default function NavBar({
  handleShowOrHideSidebar,
  isMobileSideBarHidden,
  isMobileSideBarOpen,
}: NavBarProps) {
  const client = useApolloClient();
  const userData = useCurrentUserData();
  const brandData = useCurrentBrandData();

  const isAnalyticsLayout = useIsAnalyticsLayoutPath();

  return (
    <NavBarContainer id="NavBar">
      <NavBarLogo
        href={
          brandData?.layout
            ? BRAND_LAYOUT_HOMEPAGES[brandData.layout]
            : undefined
        }
      />

      {isAnalyticsLayout && (
        <>
          <NavBarLink
            href={ANALYTICS_INDEX}
            label="Analytics"
            display={{ base: "none", md: "flex" }}
          />
          <NavBarLink href={ANALYTICS_DATA_SOURCES} label="Data Sources" />
          <NavBarLink href={PRODUCTS_INDEX} label="Products" />
        </>
      )}

      <Flex display={{ base: "none", md: "flex" }} marginLeft="auto">
        {isAnalyticsLayout === false && (
          <>
            <TooltipIconLinkButton
              href={COMMENTS_INDEX}
              label={COMMENTS_TITLE}
              icon={<Icon as={MdComment} />}
            />
            <TooltipIconLinkButton
              href={DOCUMENTS_INDEX}
              label="Document Library"
              icon={<Icon as={MdFolder} />}
            />
          </>
        )}

        <NavBarAccountMenu
          label={brandData?.name}
          image={userData?.image}
          isLoading={!userData}
        >
          <MenuItem
            as={MenuItemLink}
            href={BRAND_SETTINGS}
            icon={<MdSettings />}
          >
            Account Settings
          </MenuItem>
          <SwitchAccountMenuItem />
          {userData?.isStaff && (
            <>
              <MenuDivider />
              <MenuItem
                as={MenuItemLink}
                href={ADMIN_INDEX}
                icon={<MdAdminPanelSettings />}
                onClick={() => {
                  asyncVoid(client.clearStore());
                }}
              >
                Pitchable Admin
              </MenuItem>
            </>
          )}
          <MenuDivider />
        </NavBarAccountMenu>
      </Flex>

      {!isMobileSideBarHidden && (
        <IconButton
          icon={<HamburgerIcon boxSize={7} />}
          variant="ghost"
          display={{ md: "none" }}
          borderRadius="md"
          p={2}
          color="magenta.dark"
          aria-label="Open or close side bar menu"
          aria-expanded={isMobileSideBarOpen}
          aria-controls="SideBar"
          _expanded={{ boxShadow: "inner", bg: "gray.50" }}
          marginLeft="auto"
          marginRight={2}
          alignSelf="center"
          onClick={handleShowOrHideSidebar}
        />
      )}
    </NavBarContainer>
  );
}
