import { useCallback } from "react";
import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { useSetCurrentBrandId } from "utils/currentUserHooks";
import AccountSelection from "components/Shared/AccountSelection";

type Props = {
  onClose: () => void;
};

export default function SwitchAccountModalContent({ onClose }: Props) {
  const setCurrentBrandId = useSetCurrentBrandId();

  const handleBrandChange = useCallback(
    (brandId: string | null) => {
      setCurrentBrandId(brandId)
        .then(() => onClose())
        .catch(() => {});
    },
    [setCurrentBrandId, onClose],
  );

  return (
    <ModalContent>
      <ModalHeader color="magenta.veryDark">Switch Account</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <AccountSelection
          isIncludeInactiveShown
          onBrandSelect={handleBrandChange}
        />
      </ModalBody>
      <ModalFooter />
    </ModalContent>
  );
}
